import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { api } from "../helpers";
import BUOY_LOGO from "../assets/buoy_logo_no_tag_small.png";


const BuoyLogo = (props) => {
  return (
    <Row>
      <Col>
        <div style={{display: "flex",justifyContent: "center"}}>
          <img src={BUOY_LOGO} alt="The Buoy App Logo" width="150"></img>
        </div>
      </Col>
    </Row>
  );
};

export default BuoyLogo;
