export default {
  validateNotNullStrings(fieldNames, values) {
    let validationErrors = [];
    for (var i = 0; i < values.length; i++) {
      if (!values[i]) {
        validationErrors.push(`${fieldNames[i]} is required`);
      }
    }
    return validationErrors;
  },
};
