import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Alert from "react-s-alert";
import { api } from "../helpers";
import _ from "lodash";
import BUOY_LOGO from "../assets/buoy_logo_no_tag_small.png";
import { BuoyLogo } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showWelcomeResend, setShowWelcomeResend] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const login = (e) => {
    if (e) {
      e.preventDefault();
    }
    const errorMessage = "There was an error logging in";

    if (!email || !password) {
      Alert.warning("Email and password are required");
      return;
    }

    api
      .post("public/authenticate", {
        Email: email,
        Password: password,
      })
      .then((response) => {
        if (response.data.result === null) {
          if (response.data.errorMessages) {
            if (
              _.includes(
                response.data.errorMessages,
                "Your email or SMS number is not yet verified.  Please check your email and SMS to verify your account."
              )
            ) {
              setShowWelcomeResend(true);
            }
            Alert.error(response.data.errorMessages.join("<br/>"));
          } else {
            Alert.error(errorMessage);
          }
        } else {
          props.setCurrentUser(response.data.result);
        }
      })
      .catch((err) => Alert.error(errorMessage));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  const resendVerification = (e) => {
    if (e) {
      e.preventDefault();
    }

    const resendErrorMessage =
      "There was an error resending the account verification message";

    api
      .post("public/resendemailverification", JSON.stringify(email))
      .then((response) => {
        if (response.data.success) {
          Alert.success(
            "A verification message has been sent to your email address."
          );
        } else {
          Alert.error(response.data.message);
        }
      })
      .catch((error) => Alert.error(resendErrorMessage))
      .finally(() => setShowWelcomeResend(false));
  };

  return showWelcomeResend ? (
    <Container>
      <Row className="mt-5">
        <Col sm={4} className="center-col">
          <Button
            className="right"
            color="primary"
            onClick={(e) => resendVerification(e)}
          >
            Resend Verification
          </Button>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <BuoyLogo/>
      <Row className="mt-5">
        <Col sm={4} className="center-col">
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              className="login"
              name="email"
              type="text"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <div style={{position: "relative", display: "flex", alignItems: "center"}}> 
              <Input
                  className="login"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  />
                  <i style={{position: "absolute", right: "5%"}}>
                    <FontAwesomeIcon
                        icon={passwordShown ? "eye-slash" : "eye"}
                        onClick={() => setPasswordShown(!passwordShown)}
                    />
                </i>
              </div>
       
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col sm={4} className="center-col">
          <Link
            className="left"
            to={{
              pathname: "forgotPassword",
              state: { email: email },
            }}
          >
            Forgot Password
          </Link>
          <Button className="right" color="primary" onClick={(e) => login(e)}>
            Log In
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
