import React from "react";
import { Container } from "reactstrap";
import { NavMenu } from ".";

export default function Layout(props) {
  return (
    <div>
      <NavMenu
        currentUser={props.currentUser}
        logoff={props.logoff}
        appVersion={props.appVersion}
      />
      <Container>{props.children}</Container>
    </div>
  );
}
