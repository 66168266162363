import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "reactstrap";
import queryString from "query-string";
import { api } from "../helpers";

const ResendWelcome = (props) => {
  const [resending, setResending] = useState(true);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState(false);

  const params = queryString.parse(props.location.search);
  const email = params.email;
  const mobilePhone = params.mobilePhone;
  const token = params.token;

  const resend = () => {
    const errorMessage =
      "There was an error verifying your account.  Please click the resend verification button below to try again.";

    if (!token || (!email && !mobilePhone)) {
      setMessage(errorMessage);
      return;
    }

    const url = email
      ? "public/resendemailverification"
      : "public/resendphoneverification";

    const data = JSON.stringify(email ? email : mobilePhone);

    api
      .post(url, data)
      .then((response) => {
        if (response) {
          if (response.data) {
            setSuccess(response.data.success);
            if (response.data.success && !response.data.message) {
              const successMessage = email
                ? `A new verification email has been sent to ${email}.  Please make sure to check your spam folder if you cannot find it in your inbox. Once you click it to verify your account, try logging in.`
                : `A new verification SMS has been sent to ${mobilePhone}.  Once you click it to verify your account, try logging in.`;
              setMessage(successMessage);
            } else {
              setSuccess(false);
              setMessage(response.data.message);
            }
          }
        } else {
          setSuccess(false);
          setMessage(errorMessage);
        }
      })
      .catch((err) => {
        setSuccess(false);
        setMessage(errorMessage);
      })
      .finally(() => setResending(false));
  };

  useEffect(resend, []);

  return (
    <Container>
      {resending && (
        <Row className="mt-5">
          <Col sm={12} className="center-col">
            <h2>Resending verification message...</h2>
          </Col>
        </Row>
      )}
      {!resending && (
        <>
          <Row className="mt-5">
            <Col sm={12} className="center-col">
              <Alert color={success ? "success" : "danger"}>{message}</Alert>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ResendWelcome;
