import React from "react";
import {Row, Col} from "reactstrap";
import BUOY_LOGO from "../assets/buoy_logo_no_tag_small.png";


const Home = props => {
  return (
    <div>
      <h1>Welcome to Buoy!</h1>
    </div>
  );
};

export default Home;
