import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { api } from "../helpers";
import BUOY_LOGO from "../assets/buoy_logo_no_tag_small.png";
import { BuoyLogo } from ".";


const ForgotPassword = (props) => {
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(false);

  const [email, setEmail] = useState(props.location.state.email);

  const sendPasswordReset = () => {
    setSending(true);

    const errorMessage =
      "There was an error sending a password reset message for your account.  Please try again.";

    if (!email) {
      return;
    }

    var data = {
      email: email,
    };

    api
      .post("public/resetpassword", data)
      .then((response) => {
        if (response) {
          if (response.data) {
            setSuccess(response.data.success);
            if (response.data.success && !response.data.message) {
              setMessage(
                "A password reset message has been sent.  Please check your email or text messages for a link to reset your password."
              );
            } else {
              setMessage(response.data.message);
            }
          }
        }
      })
      .catch((err) => {
        setSuccess(false);
        setMessage(errorMessage);
      })
      .finally(() => setSending(false));
  };

  return (
    <Container>
      <BuoyLogo/>
      {sending && (
        <Row className="mt-5">
          <Col sm={12} className="center-col">
            <h2>Sending password reset message...</h2>
          </Col>
        </Row>
      )}
      {!sending && message && (
        <Row className="mt-5">
          <Col sm={12} className="center-col">
            <Alert color={success ? "success" : "danger"}>{message}</Alert>
          </Col>
        </Row>
      )}
      {!sending && !success && (
        <>
          <Row className="mt-5">
            <Col sm={4} className="center-col">
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  className="login"
                  name="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col sm={4} className="center-col">
              <Button
                className="right"
                color="primary"
                onClick={() => sendPasswordReset()}
              >
                Send Password Reset
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ForgotPassword;
