import axios from "axios";
import Alert from "react-s-alert";
//const API = "https://qaapi.thebuoyapp.com/api";
const API = "/api";

const userToken = () => localStorage.getItem("token");
const clientVersion = () => localStorage.getItem("app-version");

const headers = () => {
  const token = localStorage.getItem("token");
  return {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

const queryString = (params) => {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
  return `${query.length ? "?" : ""}${query}`;
};

axios.interceptors.response.use(
  function (response) {
    const serverVersion = response.headers["zw-version"];
    if (clientVersion === null || clientVersion !== serverVersion) {
      if (clientVersion !== undefined && serverVersion !== undefined) {
        localStorage.setItem("app-version", serverVersion);
        setTimeout(function () {
          window.location.reload();
        }, 300);
      }
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("token");
      setTimeout(function () {
        window.location.href = "/";
      }, 500);
    }
    if (error.response && error.response.status === 403) {
      Alert.error(
        "Your user account does not have the permissions to take this action"
      );
    }
    return error;
  }
);

const api = {
  fetch: (url, params = {}) => {
    return axios.get(`${API}/${url}${queryString(params)}`, {
      headers: headers(),
    });
  },
  post(url, data) {
    return axios({
      method: "post",
      url: `${API}/${url}`,
      data: data,
      headers: headers(),
    });
  },
  put(url, data) {
    return axios.put(`${API}/${url}`, data, { headers: headers() });
  },
  patch(url, data) {
    return axios.patch(`${API}/${url}`, data, { headers: headers() });
  },
  delete(url) {
    return axios.delete(`${API}/${url}`, { headers: headers() });
  },
  userToken() {
    return userToken();
  },
};

export default api;
