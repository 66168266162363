import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { BuoyLogo } from "./";
import queryString from "query-string";
import { api } from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BUOY_LOGO from "../assets/buoy_logo_no_tag_small.png";

const ResetPassword = (props) => {
  const [resetting, setResetting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const params = queryString.parse(props.location.search);
  console.log("PARAMS: ", params);
  const email = params.email;
  const mobilePhone = params.mobilePhone;
  const token = params.token;
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");

  const reset = () => {
    const errorMessage =
      "There was an error resetting your password.  Please try again.";

    if (!password || password !== confirmation) {
      setMessage(
        "The new password is required and must match the confirmation"
      );
      return;
    }

    if (!token || (!email && !mobilePhone)) {
      setMessage(errorMessage);
      return;
    }

    var data = {
      email: email,
      mobilePhoneNumber: mobilePhone,
      token: token,
      password: password,
    };

    api
      .post("public/newpassword", data)
      .then((response) => {
        if (response) {
          if (response.data) {
            setSuccess(response.data.success);
            if (response.data.success && !response.data.message) {
              setMessage(
                "Your password has been reset.  You can sign in with your new password."
              );
            } else {
              setMessage(response.data.message);
            }
          }
        }
      })
      .catch((err) => {
        setSuccess(false);
        setMessage(errorMessage);
      })
      .finally(() => setResetting(false));
  };

  return (
    <Container>
      {resetting && (
        <Row className="mt-5">
          <Col sm={12} className="center-col">
            <h2>Resetting password...</h2>
          </Col>
        </Row>
      )}
      {!resetting && message && (
        <Row className="mt-5">
          <Col sm={12} className="center-col">
            <Alert color={success ? "success" : "danger"}>{message}</Alert>
          </Col>
        </Row>
      )}
      {!resetting && !success && (
        <>
          <BuoyLogo/>
          <Row className="mt-5">
            <Col sm={4} className="center-col">
              <FormGroup>
                <Label for="password">New Password</Label>
                <div style={{position: "relative", display: "flex", alignItems: "center"}}> 
                  <Input
                    className="login"
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                      />
                      <i style={{position: "absolute", right: "5%"}}>
                        <FontAwesomeIcon
                            icon={passwordShown ? "eye-slash" : "eye"}
                            onClick={() => setPasswordShown(!passwordShown)}
                        />
                    </i>
                  </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="center-col">
              <FormGroup>
                <Label for="password">Confirm New Password</Label>
                <div style={{position: "relative", display: "flex", alignItems: "center"}}> 
                  <Input
                     className="login"
                     name="confirm"
                     type={confirmPasswordShown ? "text" : "password"}
                     onChange={(e) => setConfirmation(e.target.value)}
                      />
                      <i style={{position: "absolute", right: "5%"}}>
                        <FontAwesomeIcon
                            icon={confirmPasswordShown ? "eye-slash" : "eye"}
                            onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
                        />
                    </i>
                  </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col sm={4} className="center-col">
              <Button color="primary" className="right" onClick={() => reset()}>
                Reset Password
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ResetPassword;
