import React, { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Button,
  Card,
  CardTitle,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Alert from "react-s-alert";
import _, { first } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { api, validator } from "../helpers";

const Users = (props) => {
  const [isAdminFilter, setIsAdminFilter] = useState(true);
  const [isVerifiedFilter, setIsVerifiedFilter] = useState(false);
  const [firstNameFilter, setFirstNameFilter] = useState("");
  const [lastNameFilter, setLastNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(1);
  const [userToAdd, setUserToAdd] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [usersLoaded, setUsersLoaded] = useState(false);

  const usersApiRoot = "administration";

  const addUser = (e, empty) => {
    if (e) {
      e.preventDefault();
    }

    setUserToEdit(null);

    const newUser = empty
      ? null
      : {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          passwordConfirmation: "",
        };

    setUserToAdd(newUser);
  };

  const editUser = (e, user) => {
    if (e) {
      e.preventDefault();
    }

    setUserToAdd(null);

    if (!user) {
      setUserToEdit(null);
      return;
    }

    setUserToEdit({
      ...user,
    });
  };

  const loadUsers = () => {
    const data = {
      Page: page,
      PageSize: 20,
      IsAdmin: isAdminFilter,
      IsVerified: isVerifiedFilter,
      FirstName: firstNameFilter || null,
      LastName: lastNameFilter || null,
      Email: emailFilter || null,
    };

    api
      .post(`${usersApiRoot}/users`, data)
      .then((response) => {
        setUsers(response.data.dataSource);
        setUsersLoaded(true);
      })
      .catch((error) => {
        Alert.error("There was an error loading the users list");
      });
  };

  const saveNewUser = (e) => {
    e.preventDefault();

    let validationErrors = validator.validateNotNullStrings(
      ["First Name", "Last Name", "Email", "Password", "Password Confirmation"],
      [
        userToAdd.firstName,
        userToAdd.lastName,
        userToAdd.email,
        userToAdd.password,
        userToAdd.passwordConfirmation,
      ]
    );

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    if (userToAdd.password !== userToAdd.passwordConfirmation) {
      Alert.warning("Password and password confirmation do not match");
      return;
    }

    const newUser = {
      FirstName: userToAdd.firstName,
      LastName: userToAdd.lastName,
      Email: userToAdd.email,
      Password: userToAdd.password,
    };

    api
      .post(`${usersApiRoot}/administrator`, newUser)
      .then((response) => {
        if (!response.data.hasErrorMessages) {
          Alert.success("New administrator saved");
          addUser(null, true);
          loadUsers();
        } else {
          Alert.error(response.data.errorMessages.join("<br/>"));
          return;
        }
      })
      .catch((error) => {
        Alert.error("There was an error saving the new administrator");
      });
  };

  const updateUser = (e) => {
    e.preventDefault();

    let validationErrors = validator.validateNotNullStrings(
      ["First Name", "Last Name", "Email"],
      [userToEdit.firstName, userToEdit.lastName, userToEdit.email]
    );

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    const data = {
      Id: userToEdit.id,
      FirstName: userToEdit.firstName,
      LastName: userToEdit.lastName,
      Email: userToEdit.email,
    };

    api
      .put(`${usersApiRoot}/user`, data)
      .then((response) => {
        if (!response.data.hasErrorMessages) {
          Alert.success("User updated");
          editUser(null, null);
          loadUsers();
        } else {
          Alert.error(response.data.errorMessages.join("<br/>"));
          return;
        }
      })
      .catch((error) => {
        Alert.error("There was an error updating the user");
      });
  };

  const deactivateUser = (e, id) => {
    if (e) {
      e.preventDefault();
    }

    setUserToAdd(null);
    setUserToEdit(null);

    confirmAlert({
      title: "Deactivate User",
      message: "Are you sure you want to deactivate the user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api
              .delete(`${usersApiRoot}/user?userId=${id}`)
              .then((response) => {
                Alert.success("User deactivated");
                loadUsers();
              })
              .catch((error) => {
                Alert.error("There was an error deleting the user");
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    loadUsers();
  }, [
    isAdminFilter,
    isVerifiedFilter,
    firstNameFilter,
    lastNameFilter,
    emailFilter,
  ]);

  return (
    <Container>
      <Row>
        <Col className="left">
          <h5>User Administration</h5>
        </Col>
        <Col className="right">
          <Button
            onClick={(e) => addUser(e)}
            size="small"
            color="link"
            className="right"
          >
            <FontAwesomeIcon
              icon="plus-circle"
              className="ml-1 mt-0 mb-0 mr-2 pointer green"
            />
            Add Administrative User
          </Button>
        </Col>
      </Row>
      <Row className="filters">
        <Col xs={1}>
          <FontAwesomeIcon icon="filter" />
        </Col>
        <Col xs={2}>
          <FormGroup>
            <Label>First Name</Label>
            <Input
              type="text"
              value={firstNameFilter}
              onChange={(e) => setFirstNameFilter(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              type="text"
              value={lastNameFilter}
              onChange={(e) => setLastNameFilter(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="text"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup>
            <Label>Only Admin?</Label>
            <div style={{ marginLeft: "1.2rem" }}>
              <Input
                type="checkbox"
                checked={isAdminFilter}
                onChange={(e) => setIsAdminFilter(!isAdminFilter)}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup>
            <Label>Only Verified?</Label>
            <div style={{ marginLeft: "1.2rem" }}>
              <Input
                type="checkbox"
                checked={isVerifiedFilter}
                onChange={(e) => setIsVerifiedFilter(!isVerifiedFilter)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      {usersLoaded && (
        <React.Fragment>
          {userToAdd && (
            <Card>
              <CardBody>
                <CardTitle tag="h5">Add New Administator</CardTitle>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="firstname">First Name *</Label>
                      <Input
                        maxLength="30"
                        value={userToAdd.firstName}
                        name="firstname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={(e) =>
                          setUserToAdd({
                            ...userToAdd,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="lastname">Last Name *</Label>
                      <Input
                        maxLength="30"
                        value={userToAdd.lastName}
                        name="lastname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={(e) =>
                          setUserToAdd({
                            ...userToAdd,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="badge">Email *</Label>
                      <Input
                        maxLength="50"
                        value={userToAdd.email}
                        name="badge"
                        type="text"
                        placeholder="Enter user email"
                        onChange={(e) =>
                          setUserToAdd({
                            ...userToAdd,
                            email: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="password">Password *</Label>
                      <Input
                        value={userToAdd.password}
                        name="password"
                        type="password"
                        onChange={(e) =>
                          setUserToAdd({
                            ...userToAdd,
                            password: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="passwordConfirmation">
                        Password Confirmation *
                      </Label>
                      <Input
                        value={userToAdd.passwordConfirmation}
                        name="passwordConfirmation"
                        type="password"
                        onChange={(e) =>
                          setUserToAdd({
                            ...userToAdd,
                            passwordConfirmation: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button
                      color="primary"
                      onClick={(e) => saveNewUser(e)}
                      className="right"
                    >
                      Save New User
                    </Button>
                    <Button
                      onClick={(e) => addUser(e, true)}
                      className="left-button right"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          {userToEdit && (
            <Card>
              <CardBody>
                <CardTitle tag="h5">Update User</CardTitle>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="firstname">First Name *</Label>
                      <Input
                        maxLength="30"
                        value={userToEdit.firstName || ""}
                        name="firstname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={(e) =>
                          setUserToEdit({
                            ...userToEdit,
                            userToEdit: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="lastname">Last Name *</Label>
                      <Input
                        maxLength="30"
                        value={userToEdit.lastName || ""}
                        name="lastname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={(e) =>
                          setUserToEdit({
                            ...userToEdit,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="badge">Email *</Label>
                      <Input
                        maxLength="50"
                        value={userToEdit.email || ""}
                        name="badge"
                        type="text"
                        placeholder="Enter user email"
                        onChange={(e) =>
                          setUserToEdit({
                            ...userToEdit,
                            email: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button
                      color="primary"
                      onClick={(e) => updateUser(e)}
                      className="right"
                    >
                      Update User
                    </Button>
                    <Button
                      onClick={(e) => editUser(e, null)}
                      className="left-button right"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          <Row>
            {users && users.length && users.length > 0 ? (
              users.map((x) => (
                <Col key={x.id} xs={12} sm={6} className="mt-5">
                  <Card>
                    <CardBody>
                      <CardTitle tag="h5">
                        {x.firstName} {x.lastName}
                        <div className="right">
                          <Button
                            onClick={(e) => deactivateUser(e, x.id)}
                            color="danger"
                            className="btn-sm left-button"
                          >
                            <FontAwesomeIcon
                              icon="trash-alt"
                              className="pointer"
                            />
                          </Button>
                          <Button
                            onClick={(e) => editUser(e, x)}
                            color="primary"
                            className="btn-sm"
                          >
                            <FontAwesomeIcon icon="edit" className="pointer" />
                          </Button>
                        </div>
                      </CardTitle>
                      <Row className="user-details">
                        <Col xs={12}>{x.email}</Col>
                      </Row>
                      {x.IsAdmin && (
                        <Row>
                          <Col xs={12}>
                            <Badge color="warning">Administrator</Badge>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col className="notification">There are no active users</Col>
            )}
          </Row>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Users;
