import { Button, Row, Col, Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ErrorBoundary } from "react-error-boundary";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const goBack = () => {
  window.history.back();
};

const AppFallBackComponent = ({ componentStack, error }) => {
  console.log("componentStack :\n", componentStack, "\n\n error : \n\n", error);
  return (
    <Container>
      <Row className="m-5">
        <Col>
          <h4>Something went wrong on our side.</h4>
        </Col>
      </Row>
      <Row className="m-5">
        <Col>
          <h4>
            Please use your browser&#39;s back button to return to the previous
            screen.
          </h4>
        </Col>
      </Row>
      <Row className="m-5">
        <Col>
          <h4>
            <Button onClick={goBack}>Or click here</Button>
          </h4>
        </Col>
      </Row>
    </Container>
  );
};

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ErrorBoundary FallbackComponent={AppFallBackComponent}>
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
  rootElement
);
