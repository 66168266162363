import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Alert, Col, Container, Row } from "reactstrap";
import queryString from "query-string";
import { api } from "../helpers";

const Verify = (props) => {
  const [verifying, setVerifying] = useState(true);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState(false);
  const history = useHistory();

  const params = queryString.parse(props.location.search);
  const email = params.email;
  const mobilePhone = params.mobilePhone;
  const token = params.token;

  const verify = () => {

    const errorMessage =
      "There was an error verifying your account.  Please click the resend verification button below to try again.";

    if (!token || (!email && !mobilePhone)) {
      setMessage(errorMessage);
      return;
    }

    const url = email
      ? `public/verifyemail?email=${encodeURIComponent(
        email
      )}&token=${encodeURIComponent(token)}`
      : `public/verifyphone?mobilePhone=${encodeURIComponent(
        mobilePhone
      )}&token=${encodeURIComponent(token)}`;

    api
      .fetch(url)
      .then((response) => {
        if (response) {
          if (response.data) {
            setSuccess(response.data.success);
            if (response.data.success && !response.data.message) {
              // props.navigation && props.navigation.navigate()
              // return api.fetch("public/redirectToMobile");
              // history.push("/api/public/redirectToMobile")
              setMessage(
                "Your account has been verified.  You can sign in and get started!"
              );
            } else {
              setMessage(response.data.message);
            }
          }
        }
      })
      // .then(r => {
      //   console.log(r);
      //   history.push(r);
      // })
      .catch((_err) => {
        setSuccess(false);
        setMessage(errorMessage);
      })
      .finally(() => setVerifying(false));
  };

  useEffect(verify, []);

  return (
    <Container>
      {verifying && (
        <Row className="mt-5">
          <Col sm={12} className="center-col">
            <h2>Verifying account...</h2>
          </Col>
        </Row>
      )}
      {!verifying && (
        <>
          <Row className="mt-5">
            <Col sm={12} className="center-col">
              <Alert color={success ? "success" : "danger"}>{message}</Alert>
              {success ? <Link
                to="/api/public/redirectToMobile/signIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </Link>
                : null
              }
            </Col>
          </Row>
          {!success && (
            <Row className="mt-1">
              <Col sm={4} className="center-col">
                <Link
                  to={{
                    pathname: "/resendWelcome",
                    state: { email: email, mobilePhone: mobilePhone },
                  }}
                >
                  Resend Verification
                </Link>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Verify;
