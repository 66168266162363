import React, { Component, useState } from "react";
import { Route, Redirect } from "react-router";
import {
  ForgotPassword,
  Home,
  Layout,
  Login,
  ResendWelcome,
  ResetPassword,
  Users,
  Verify,
} from "./components";
import { constants } from "./helpers";
import "./custom.css";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faExternalLinkAlt,
  faFilter,
  faInfoCircle,
  faMinusSquare,
  faPlusCircle,
  faPlusSquare,
  faQuestion,
  faSignOutAlt,
  faTimes,
  faTrashAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
library.add(faEdit);
library.add(faExternalLinkAlt);
library.add(faFilter);
library.add(faInfoCircle);
library.add(faMinusSquare);
library.add(faPlusCircle);
library.add(faPlusSquare);
library.add(faQuestion);
library.add(faSignOutAlt);
library.add(faTimes);
library.add(faTrashAlt);
library.add(faEye);
library.add(faEyeSlash);

export default class App extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem(constants.Token);
    let currentUser = token
      ? JSON.parse(localStorage.getItem(constants.CurrentUser))
      : null;
    this.state = {
      authenticationToken: token,
      currentUser: currentUser,
      appVersion: localStorage.getItem("version") || "",
      message: null,
    };
    this.logoff = this.logoff.bind(this);
    this.setCurrentUser = this.setCurrentUser.bind(this);
  }

  logoff() {
    const { Token, CurrentUser } = constants;

    localStorage.removeItem(Token);
    localStorage.removeItem(CurrentUser);

    this.setState({
      currentUser: null,
      authenticationToken: null,
    });
  }

  setCurrentUser(user) {
    const { Token, CurrentUser } = constants;

    localStorage.setItem(CurrentUser, JSON.stringify(user));
    let newState = {
      currentUser: user,
    };
    if (user.token) {
      localStorage.setItem(Token, user.token);
      newState.authenticationToken = user.token;
    }
    this.setState(newState);
  }

  render() {
    const AuthRoute = ({ component: Component, ...extraProps }) => {
      return (
        <Route
          {...extraProps}
          render={(props) => {
            return this.state.authenticationToken ? (
              <Component {...extraProps} currentUser={this.state.currentUser} />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
      );
    };

    const LoginRoute = ({ ...extraProps }) => (
      <Route
        {...extraProps}
        render={(props) => {
          return this.state.authenticationToken ? (
            <Redirect to="/" />
          ) : (
            <Login {...extraProps} setCurrentUser={this.setCurrentUser} />
          );
        }}
      />
    );

    return (
      <Layout
        currentUser={this.state.currentUser}
        logoff={this.logoff}
        appVersion={this.state.appVersion}
        showMenu={this.state.authenticationToken}
      >
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/resendWelcome" component={ResendWelcome} />
        <Route exact path="/reset" component={ResetPassword} />
        <LoginRoute exact path="/login" currentUser={this.state.currentUser} />
        <AuthRoute exact path="/" component={Home} />
        <AuthRoute exact path="/users" component={Users} />
        <Alert
          effect="stackslide"
          position="top-right"
          stack={{ limit: 5 }}
          timeout={7000}
          html={true}
        />
      </Layout>
    );
  }
}
