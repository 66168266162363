import React, { useState } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";

export default function NavMenu(props) {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        light
      >
        <Container>
          <NavbarBrand
            tag={Link}
            to="/"
            title={props.currentUser ? props.appVersion : ""}
          >
            The Buoy App
          </NavbarBrand>
          {props.currentUser && (
            <React.Fragment>
              <NavbarToggler onClick={() => toggleNavbar()} className="mr-2" />
              <Collapse
                className="d-sm-inline-flex flex-sm-row-reverse"
                isOpen={!collapsed}
                navbar
              >
                <ul className="navbar-nav flex-grow">
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/">
                      Dashboard
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/users">
                      Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => props.logoff()}
                      tag={Link}
                      className="text-dark"
                      to="/"
                    >
                      Log Out
                    </NavLink>
                  </NavItem>
                </ul>
              </Collapse>
            </React.Fragment>
          )}
        </Container>
      </Navbar>
    </header>
  );
}
